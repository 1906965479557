import { Button, Card, CardBody, Input, Spinner, Typography } from "@material-tailwind/react";
import { Tabs, TabsHeader, TabsBody, Tab, TabPanel } from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import { changeUserCountry, getCountries, getUserDetails, setUserName, setUserPassword } from "../../../helpers/AppUserApi";
import { toast } from "react-hot-toast";
import { createStripeSession, getStripeCustomerDetails, linkAccountWithCustomerId } from "../../../helpers/StripeApi";
import { Select } from "antd";
import Countries from "../../../data/countries.json";

const Profile = () => {
  const [activeTab, setActiveTab] = useState("profileDetails");
  const [userData, setUserData] = useState(null);
  const [checkMinimumAccountsFetched, setCheckMinimumAccountsFetched] = useState(false);
  const [changeNameValue, setChangeNameValue] = useState(null);
  const [currentPassValue, setcurrentPassValue] = useState(null);
  const [newPassValue, setnewPassValue] = useState(null);
  const [countrySelected, setCountrySelected] = useState(null);
  const [linkedCustomerBool, setLinkedCustomerBool] = useState(true);
  const [loadedData, setLoadedData] = useState([
    {
      index: "linkButton",
      status: true,
    },
  ]);

  const handleLoadedStatus = (status, index) => {
    // console.log(e, index)
    setLoadedData((prevObjects) => {
      const updatedObjects = [...prevObjects];

      const objectIndex = updatedObjects.findIndex((obj) => obj.index === index);

      if (objectIndex !== -1) {
        const updatedObject = {
          ...updatedObjects[objectIndex],
          status: status,
        };

        updatedObjects[objectIndex] = updatedObject;
      }

      return updatedObjects;
    });
  };

  const getUserDataFunc = () => {
    let toastBox = toast;
    const getUserDetailsPromise = getUserDetails();
    getUserDetailsPromise
      .then(
        (data) => {
          setUserData(data);
          setCheckMinimumAccountsFetched(true);
        },
        (msg) => {
          setCheckMinimumAccountsFetched(true);
        }
      )
      .catch((err) => {
        toast.error(`${err}`, {
          id: toastBox,
        });
      });
  };

  const handleChangeName = () => {
    let toastBox = toast;
    toast.loading(`loading...`, {
      id: toastBox,
    });
    if (changeNameValue) {
      const setUserNamePromise = setUserName({ name: changeNameValue });
      setUserNamePromise
        .then(
          (data) => {
            toast.success(`Name changed successfully`, {
              id: toastBox,
            });
            window.location.reload();
          },
          (data) => {
            toast.error(`${data.msg}`, {
              id: toastBox,
            });
          }
        )
        .catch((err) => {
          toast.error(`${err}`, {
            id: toastBox,
          });
        });
    } else {
      toast.error(`Please enter a name`, {
        id: toastBox,
      });
    }
  };

  const handleChangePassword = () => {
    let toastBox = toast;
    toast.loading(`loading...`, {
      id: toastBox,
    });
    if (currentPassValue && newPassValue) {
      const setUserPasswordPromise = setUserPassword({
        currentpass: currentPassValue,
        newpass: newPassValue,
      });
      setUserPasswordPromise
        .then(
          (data) => {
            toast.success(`Password changed successfully`, {
              id: toastBox,
            });
            window.location.reload();
          },
          (msg) => {
            toast.error(`${msg}`, {
              id: toastBox,
            });
          }
        )
        .catch((err) => {
          toast.error(`${err}`, {
            id: toastBox,
          });
        });
    } else {
      toast.error(`Please enter all details`, {
        id: toastBox,
      });
    }
  };

  const handleManageSubscriptionButton = () => {
    let toastBox = toast;
    const verifyCustomerPromise = getStripeCustomerDetails();
    verifyCustomerPromise
      .then(
        (type) => {
          if (type === "LINKED_ID") createCustomerSessionFunc();
          else if (type === "NOT_LINKED_ID") {
            setLinkedCustomerBool(false);
          }
        },
        (msg) => {
          toast.error(`${msg}`, {
            id: toastBox,
          });
        }
      )
      .catch((err) => {
        toast.error(`${err}`, {
          id: toastBox,
        });
      });
  };

  const createCustomerSessionFunc = () => {
    let toastBox = toast;
    const manageSubscriptionPromise = createStripeSession();
    manageSubscriptionPromise
      .then(
        (data) => {
          if (data.url) window.location.replace(`${data.url}`);
        },
        (msg) => {
          toast.error(`${msg}`, {
            id: toastBox,
          });
        }
      )
      .catch((err) => {
        toast.error(`${err}`, {
          id: toastBox,
        });
      });
  };

  const handleAccountLinkButton = () => {
    let toastBox = toast;
    handleLoadedStatus(false, "linkButton");
    const linkAccountWithCustomerPromise = linkAccountWithCustomerId();
    linkAccountWithCustomerPromise
      .then(
        (data) => {
          window.location.reload();
        },
        (msg) => {
          if (msg === "MULTIPLE_CUSTOMER_FOUND") {
            toast.error(`Multiple customers found. Please contact support`, {
              id: toastBox,
            });
          } else {
            toast.error(`${msg}`, {
              id: toastBox,
            });
          }
        }
      )
      .catch((err) => {
        toast.error(`${err}`, {
          id: toastBox,
        });
      })
      .finally(() => {
        handleLoadedStatus(true, "linkButton");
      });
  };

  const handleCustomerDetailsFetchButton = () => {
    let toastBox = toast;
    const getStripeCustomerDetailsPromise = getStripeCustomerDetails();
    getStripeCustomerDetailsPromise
      .then(
        (data) => {
          console.log(data);
        },
        (msg) => {
          toast.error(`${msg}`, {
            id: toastBox,
          });
        }
      )
      .catch((err) => {
        toast.error(`${err}`, {
          id: toastBox,
        });
      });
  };

  const handleCountryChange = () => {
    let toastBox = toast;
    const getStripeCustomerDetailsPromise = changeUserCountry({ countryCode: countrySelected });
    getStripeCustomerDetailsPromise
      .then(
        (data) => {
          setUserData({ ...userData, countryCode: countrySelected });
          toast.success(`Location changed successfully`, {
            id: toastBox,
          });
        },
        (msg) => {
          toast.error(`${msg}`, {
            id: toastBox,
          });
        }
      )
      .catch((err) => {
        toast.error(`${err}`, {
          id: toastBox,
        });
      });
  };

  useEffect(() => {
    getUserDataFunc();
  }, []);

  useEffect(() => {
    if (userData?.countryCode) setCountrySelected(userData?.countryCode);
  }, [userData]);

  return (
    <>
      {checkMinimumAccountsFetched ? (
        <div className="md:w-full w-11/12">
          <div className="w-full sm:pl-14 pt-10 sm:text-start mx-auto text-center">
            <h1 className="text-2xl">User Profile</h1>
          </div>
          <div className="w-11/12 mt-2.5 mx-auto justify-center">
            <hr />
          </div>
          {/* <div className="w-full mx-auto justify-center text-center items-center pt-5">
            <div className="bg-blue-gray-50 p-4 pt-0 w-11/12 mx-auto rounded-md">
              <div className="w-full mx-auto justify-center">
                <Typography
                  color="blue-gray"
                  className="font-semibold text-xl sm:text-3xl leading-none opacity-70 w-full text-start p-5"
                >
                  Current usage details
                </Typography>
                <hr className="border-gray-300" />
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 pt-4">
                <div className="w-full mx-auto">
                  <div className="w-28 h-28 rounded-full bg-green-400 mx-auto">
                    <div className="h-full w-full place-items-center mx-auto justify-center flex text-white font-bold text-xl">
                      {clientContacts}
                    </div>
                  </div>
                  <Typography
                    color="blue-gray"
                    className="font-normal text-lg leading-none opacity-70 w-full text-center p-5"
                  >
                    Clients / contacts transferred
                  </Typography>
                </div>
                <div className="w-full mx-auto">
                  <div className="w-28 h-28 rounded-full bg-orange-300 mx-auto">
                    <div className="h-full w-full place-items-center mx-auto justify-center flex text-white font-bold text-xl">
                      {appointments}
                    </div>
                  </div>
                  <Typography
                    color="blue-gray"
                    className="font-normal text-lg leading-none opacity-70 w-full text-center p-5"
                  >
                    Appointments transferred
                  </Typography>
                </div>
                <div className="w-full mx-auto">
                  <div className="w-28 h-28 rounded-full bg-blue-500 mx-auto">
                    <div className="h-full w-full place-items-center mx-auto justify-center flex text-white font-bold text-xl">
                      {memberPurchased}
                    </div>
                  </div>
                  <Typography
                    color="blue-gray"
                    className="font-normal text-lg leading-none opacity-70 w-full text-center p-5"
                  >
                    Membership purchased
                  </Typography>
                </div>
                <div className="w-full mx-auto">
                  <div className="w-28 h-28 rounded-full bg-purple-500 mx-auto">
                    <div className="h-full w-full place-items-center mx-auto justify-center flex text-white font-bold text-xl">
                      {memberCancelled}
                    </div>
                  </div>
                  <Typography
                    color="blue-gray"
                    className="font-normal text-lg leading-none opacity-70 w-full text-center p-5"
                  >
                    Membership cancelled
                  </Typography>
                </div>
              </div>
            </div>
          </div> */}
          <Tabs value={activeTab}>
            <div className="w-2/4 mx-auto mt-4">
              <TabsHeader className="min-w-max max-w-lg mx-auto">
                <Tab value={"profileDetails"} onClick={() => setActiveTab("profileDetails")} className={activeTab === "profileDetails" ? "text-blue-500" : ""}>
                  Profile settings
                </Tab>
                <Tab
                  value={"manageSub"}
                  onClick={() => {
                    setActiveTab("manageSub");
                    handleManageSubscriptionButton();
                  }}
                  className={activeTab === "manageSub" ? "text-blue-500 min-w-max" : "min-w-max"}
                >
                  Manage subscription
                </Tab>
              </TabsHeader>
            </div>

            <TabsBody>
              {activeTab === "profileDetails" && (
                <div className="w-11/12 mx-auto grid grid-cols-1 lg:grid-cols-none lg:grid-rows-4 lg:grid-flow-col gap-4">
                  <div className="bg-gray-50 p-4 pt-0 mt-4 w-full rounded-md col-span-1 row-span-2">
                    <div className="w-full mx-auto justify-center">
                      <Typography color="blue" className="font-normal text-lg leading-none opacity-70 w-full text-start p-5 pl-0 ">
                        Basic details
                      </Typography>
                      <hr className="border-gray-300" />
                    </div>
                    <div className="pt-5 flex flex-wrap">
                      <div>
                        <Typography color="black" className="font-normal text-lg leading-none opacity-70 w-full text-start pt-3">
                          Profile name:
                        </Typography>
                      </div>
                      <div className="w-max ml-4">
                        <Typography color="blue-gray" className="font-normal text-lg leading-none opacity-70 w-full text-start pt-3">
                          {userData.name}
                        </Typography>
                      </div>
                    </div>
                    <div className="pt-5 flex flex-wrap">
                      <div>
                        <Typography color="black" className="font-normal text-lg leading-none opacity-70 w-full text-start pt-3">
                          Email:
                        </Typography>
                      </div>
                      <div className="w-min ml-4">
                        <Typography color="blue-gray" className="font-normal text-lg leading-none opacity-70 w-full text-start pt-3">
                          {userData.email}
                        </Typography>
                      </div>
                    </div>
                    <div className="pt-5 flex flex-wrap">
                      <div>
                        <Typography color="black" className="font-normal text-lg leading-none opacity-70 w-full text-start pt-3">
                          Profile type:
                        </Typography>
                      </div>
                      <div className="w-min ml-4">
                        <Typography color="blue-gray" className="font-normal text-lg leading-none opacity-70 w-full text-start pt-3">
                          {userData.type}
                        </Typography>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 p-4 pt-0 mt-4 w-full rounded-md col-span-1 row-span-2">
                    <div className="w-full mx-auto justify-center">
                      <Typography color="orange" className="font-normal text-lg leading-none opacity-70 w-full text-start p-5 pl-0">
                        Change profile name
                      </Typography>
                      <hr className="border-gray-300" />
                    </div>
                    <div className="w-1/2 mt-10">
                      <Input
                        label="Enter new name"
                        size="lg"
                        value={changeNameValue ? changeNameValue : ""}
                        onChange={(e) => {
                          setChangeNameValue(e.target.value);
                        }}
                      />
                    </div>
                    <div className="w-1/2 mt-4">
                      <Button type="button" color="green" variant="gradient" onClick={handleChangeName}>
                        Change
                      </Button>
                    </div>
                  </div>
                  <div className="bg-gray-50 p-4 pt-0 mt-4 w-full rounded-md col-span-1 row-span-2">
                    <div className="w-full mx-auto justify-center">
                      <Typography color="orange" className="font-normal text-lg leading-none opacity-70 w-full text-start p-5 pl-0">
                        Change password
                      </Typography>
                      <hr className="border-gray-300" />
                    </div>
                    <div className="w-1/2 mt-4">
                      <Input
                        label="Enter current password"
                        size="lg"
                        type="password"
                        value={currentPassValue ? currentPassValue : ""}
                        onChange={(e) => {
                          setcurrentPassValue(e.target.value);
                        }}
                      />
                    </div>
                    <div className="w-1/2 mt-4">
                      <Input
                        label="Enter new password"
                        size="lg"
                        type="password"
                        value={newPassValue ? newPassValue : ""}
                        onChange={(e) => {
                          setnewPassValue(e.target.value);
                        }}
                      />
                    </div>
                    <div className="w-1/2 mt-4">
                      <Button type="button" color="green" variant="gradient" onClick={handleChangePassword}>
                        Change
                      </Button>
                    </div>
                  </div>
                  <div className="bg-gray-50 p-4 pt-0 mt-4 w-full rounded-md col-span-1 row-span-2">
                    <div className="w-full mx-auto justify-center">
                      <Typography color="orange" className="font-normal text-lg leading-none opacity-70 w-full text-start p-5 pl-0">
                        Change location
                      </Typography>
                      <hr className="border-gray-300" />
                    </div>
                    <div className="w-1/2 mt-10">
                      <Select size="large" placeholder="Select country" className="min-w-full" value={countrySelected} onChange={(e) => setCountrySelected(e)}>
                        {Countries.map((element) => (
                          <Select.Option key={element.countryCode} value={element.countryCode}>
                            {element.countryName}
                          </Select.Option>
                        ))}
                      </Select>
                    </div>
                    <div className="w-1/2 mt-4">
                      <Button type="button" color="green" variant="gradient" onClick={handleCountryChange} disabled={countrySelected === userData?.countryCode}>
                        Change
                      </Button>
                    </div>
                  </div>
                  {/* <div className="bg-gray-50 p-4 pt-0 mt-4 w-full rounded-md col-span-1 row-span-2">
  <div className="w-full mx-auto justify-center">
    <Typography
      color="purple"
      className="font-normal text-lg leading-none opacity-70 w-full text-start p-5 pl-0"
    >
      Manage your subscription
    </Typography>
    <hr className="border-gray-300" />
  </div>
  <div className="w-1/2 mt-4 items-center">
    <Button
      type="button"
      color="blue"
      variant="gradient"
      onClick={handleManageSubscriptionButton}
    >
      MANAGE SUBSCRIPTION
    </Button>
  </div>
  <div className="w-1/2 mt-4">
    <Button
      type="button"
      color="green"
      variant="gradient"
      onClick={handleCustomerDetailsFetchButton}
    >
      Get customer details
    </Button>
  </div>
</div>; */}
                </div>
              )}

              {activeTab === "manageSub" && (
                <div className="w-11/12 mx-auto flex flex-wrap">
                  {linkedCustomerBool ? (
                    <div className="flex place-items-center max-w-fit m-auto justify-center" style={{ height: "50vh" }}>
                      <Spinner className="h-6 w-6" />
                      <div className="ml-4">Please wait, fetching details...</div>
                    </div>
                  ) : (
                    <div className="w-full mx-auto justify-center">
                      <div className="w-2/4 flex flex-col place-items-center m-auto justify-center" style={{ height: "50vh" }}>
                        <div className="w-full mx-auto text-center text-red-900">To access your stripe portal, account link is mandatory!</div>
                        <div className="w-full mt-4">
                          <Card className="w-2/4 place-items-center m-auto justify-center bg-blue-gray-50">
                            <CardBody>
                              {loadedData.filter((element) => element.index === "linkButton")[0].status ? (
                                <Button onClick={handleAccountLinkButton}>Link account with Stripe</Button>
                              ) : (
                                <Button disabled>
                                  <div className="flex place-items-center w-full h-full m-auto justify-center">
                                    <Spinner />
                                  </div>
                                </Button>
                              )}
                            </CardBody>
                          </Card>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </TabsBody>
          </Tabs>
        </div>
      ) : (
        <div className="flex place-items-center w-full h-full m-auto justify-center">
          <Spinner className="h-12 w-12" />
        </div>
      )}
    </>
  );
};

export default Profile;
