import axios from "axios";
import { API_URL, APP_GHL_BASE_URL } from "./AppApiUrls";

const BASE_URL = `${API_URL}${APP_GHL_BASE_URL}`;
//const BASE_URL = "https://www.webhook.synx.ai/gohighlevel";
//const BASE_URL = "http://13.232.11.86:8000/gohighlevel";
//const BASE_URL = "http://192.168.0.109:8000/gohighlevel";

export async function GHLAuthTokenGenCallback(code) {
  try {
    console.log(code);
    const { data } = await axios.post(
      `${BASE_URL}/account/oauth`,
      { code: code },
      {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      }
    );
    console.log(data);
    if (data.success) return Promise.resolve(data?.data);
    else return Promise.reject(data?.error);
  } catch (err) {
    return Promise.reject("Auth Failed");
  }
}

export async function getAllGHLAccountsSpecificUser() {
  try {
    const userToken = await localStorage.getItem("token");
    const { data } = await axios.get(`${BASE_URL}/account/accounts`, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    // console.log(data)
    if (data.msg === "success") return Promise.resolve(data.result);
    else {
      console.log(data.msg);
      return Promise.reject("Failed to retrieve data");
    }
  } catch (err) {
    return Promise.reject({ error: "Auth Failed" });
  }
}

// export async function getAllCustomFieldDetails() {
//   try {
//     const userToken = await localStorage.getItem("token");
//     const { data } = await axios.get(`${BASE_URL}/account/accounts`, {
//       headers: { "Content-Type": "application/json" },
//      withCredentials: true,
//     });
//     // console.log(data)
//     if (data.msg === "success") return Promise.resolve(data.result);
//     else {
//       console.log(data.msg);
//       return Promise.reject("Failed to retrieve data");
//     }
//   } catch (err) {
//     return Promise.reject({ error: "Auth Failed" });
//   }
// }

export async function getAllCalendarDetails(apidetails) {
  try {
    const {
      data: { msg, result },
    } = await axios.post(`${BASE_URL}/calendar/calendars`, apidetails);
    console.log(msg, result);
    if (msg === "success") return Promise.resolve(result);
    else return Promise.reject(msg);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getCustomFieldDetails(apidetails) {
  try {
    const {
      data: { msg, result },
    } = await axios.post(`${BASE_URL}/contact/field`, apidetails);
    if (msg === "success") return Promise.resolve(result);
    else return Promise.reject(msg);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function updateCustomFieldDetails(details) {
  try {
    const {
      data: { msg },
    } = await axios.put(`${BASE_URL}/account/customfield`, details, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    if (msg === "success") return Promise.resolve(msg);
    else return Promise.reject(msg);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function addGHLData(userdetails) {
  try {
    const userToken = await localStorage.getItem("token");
    const {
      data: { msg, result },
    } = await axios.post(`${BASE_URL}/authdetails/add`, userdetails, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    if (msg === "success") return Promise.resolve(result);
    else return Promise.reject(msg);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function GHLDataUpdate(userdetails) {
  try {
    const userToken = await localStorage.getItem("token");
    const {
      data: { msg, result },
    } = await axios.patch(`${BASE_URL}/authdetails/update`, userdetails, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    if (msg === "success") return Promise.resolve(result);
    else return Promise.reject(msg);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function addNewCalendar(calendardetails) {
  try {
    const {
      data: { msg },
    } = await axios.post(`${BASE_URL}/calendar/add`, calendardetails, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    if (msg === "success") return Promise.resolve(msg);
    else return Promise.reject(msg);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function RemoveCalendar(calendardetails) {
  try {
    const {
      data: { msg },
    } = await axios.delete(`${BASE_URL}/calendar/remove`, {
      data: calendardetails,
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    if (msg === "success") return Promise.resolve(msg);
    else return Promise.reject(msg);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getAllAddedGHLCalendars(apidetails) {
  try {
    const userToken = await localStorage.getItem("token");
    const { data } = await axios.get(`${BASE_URL}/calendar/calendars`, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
      params: { ghl_id: apidetails.ghl_id },
    });
    // console.log(data)
    if (data.msg === "success") return Promise.resolve(data.result);
    else return Promise.reject({ msg: "Calendars retrieve failed" });
  } catch (err) {
    return Promise.reject({ error: "Auth Failed" });
  }
}

export async function deleteGhlData(details) {
  try {
    const userToken = await localStorage.getItem("token");
    const {
      data: { msg },
    } = await axios.delete(`${BASE_URL}/authdetails/delete`, {
      data: details,
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    if (msg === "success") return Promise.resolve(msg);
    else return Promise.reject(msg);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function addContactWebhook(contactdetails) {
  try {
    const userToken = await localStorage.getItem("token");
    const {
      data: { msg },
    } = await axios.post(`${BASE_URL}/contact/webhook/add`, contactdetails, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    if (msg === "success") return Promise.resolve(msg);
    else return Promise.reject(msg);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function addClassBookingWebhook(appdetails) {
  try {
    const userToken = await localStorage.getItem("token");
    const {
      data: { msg },
    } = await axios.post(`${BASE_URL}/appointment/webhook/add`, appdetails, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    if (msg === "success") return Promise.resolve(msg);
    else return Promise.reject(msg);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function addMembershipWebhook(appdetails) {
  try {
    const userToken = await localStorage.getItem("token");
    const {
      data: { msg },
    } = await axios.post(`${BASE_URL}/membership/webhook/add`, appdetails, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    if (msg === "success") return Promise.resolve(msg);
    else return Promise.reject(msg);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function updateGhlClassBookingWebhook(appdetails) {
  try {
    const userToken = await localStorage.getItem("token");
    const {
      data: { msg },
    } = await axios.put(`${BASE_URL}/appointment/webhook/update`, appdetails, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    if (msg === "success") return Promise.resolve(msg);
    else return Promise.reject(msg);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function updateGhlAppointmentBookingWebhook(appdetails) {
  try {
    const userToken = await localStorage.getItem("token");
    const {
      data: { msg },
    } = await axios.put(`${BASE_URL}/appointments/webhook/update`, appdetails, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    if (msg === "success") return Promise.resolve(msg);
    else return Promise.reject(msg);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function updateGhlMembershipWebhook(appdetails) {
  try {
    const userToken = await localStorage.getItem("token");
    const {
      data: { msg },
    } = await axios.put(`${BASE_URL}/membership/webhook/update`, appdetails, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    if (msg === "success") return Promise.resolve(msg);
    else return Promise.reject(msg);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function updateGhlContactWebhook(appdetails) {
  try {
    const userToken = await localStorage.getItem("token");
    const {
      data: { msg },
    } = await axios.put(`${BASE_URL}/contact/webhook/update`, appdetails, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    if (msg === "success") return Promise.resolve(msg);
    else return Promise.reject(msg);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function addAppointmentWebhook(appdetails) {
  try {
    const userToken = await localStorage.getItem("token");
    const {
      data: { msg },
    } = await axios.post(`${BASE_URL}/appointments/webhook/add`, appdetails, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    if (msg === "success") return Promise.resolve(msg);
    else return Promise.reject(msg);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getContactWebhooks() {
  try {
    const userToken = await localStorage.getItem("token");
    const { data } = await axios.get(`${BASE_URL}/contact/webhook/webhooks`, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    // console.log(data)
    if (data.msg === "success") return Promise.resolve(data.result);
    else if (data.msg === "NO_WEBHOOKS") return Promise.reject(data.msg);
    else return Promise.reject({ msg: "Contact webhooks retrieve failed" });
  } catch (err) {
    return Promise.reject({ error: "Auth Failed" });
  }
}

export async function getMembershipWebhooks() {
  try {
    const userToken = await localStorage.getItem("token");
    const { data } = await axios.get(`${BASE_URL}/membership/webhook/webhooks`, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    // console.log(data)
    if (data.msg === "success") return Promise.resolve(data.result);
    else if (data.msg === "NO_WEBHOOKS") return Promise.reject(data.msg);
    else return Promise.reject({ msg: "Membership webhooks retrieve failed" });
  } catch (err) {
    return Promise.reject({ error: "Auth Failed" });
  }
}

export async function getAppointmentGHLWebhooks() {
  try {
    const userToken = await localStorage.getItem("token");
    const { data } = await axios.get(`${BASE_URL}/appointments/webhook/webhooks`, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    // console.log(data)
    if (data.msg === "success") return Promise.resolve(data.result);
    else if (data.msg === "NO_WEBHOOKS") return Promise.reject(data.msg);
    else
      return Promise.reject({
        msg: "Appointment webhooks retrieve failed",
        reason: data,
      });
  } catch (err) {
    return Promise.reject({ error: "Auth Failed" });
  }
}

export async function getClassBookingGHLWebhooks() {
  try {
    const userToken = await localStorage.getItem("token");
    const { data } = await axios.get(`${BASE_URL}/appointment/webhook/webhooks`, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    // console.log(data)
    if (data.msg === "success") return Promise.resolve(data.result);
    else if (data.msg === "NO_WEBHOOKS") return Promise.reject(data.msg);
    else
      return Promise.reject({
        msg: "Appointment webhooks retrieve failed",
        reason: data,
      });
  } catch (err) {
    return Promise.reject({ error: "Auth Failed" });
  }
}

export async function deleteContactWebhook(userdetails) {
  try {
    const userToken = await localStorage.getItem("token");
    const {
      data: { msg },
    } = await axios.delete(`${BASE_URL}/contact/webhook/delete`, {
      data: userdetails,
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    if (msg === "success") return Promise.resolve(msg);
    else return Promise.reject(msg);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function deleteMembershipWebhook(userdetails) {
  try {
    const userToken = await localStorage.getItem("token");
    const {
      data: { msg },
    } = await axios.delete(`${BASE_URL}/membership/webhook/delete`, {
      data: userdetails,
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    if (msg === "success") return Promise.resolve(msg);
    else return Promise.reject(msg);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function deleteClassBookingWebhook(userdetails) {
  try {
    const userToken = await localStorage.getItem("token");
    const {
      data: { msg },
    } = await axios.delete(`${BASE_URL}/appointment/webhook/delete`, {
      data: userdetails,
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    if (msg === "success") return Promise.resolve(msg);
    else return Promise.reject(msg);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function deleteAppointmentWebhook(userdetails) {
  try {
    const userToken = await localStorage.getItem("token");
    const {
      data: { msg },
    } = await axios.delete(`${BASE_URL}/appointments/webhook/delete`, {
      data: userdetails,
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    if (msg === "success") return Promise.resolve(msg);
    else return Promise.reject(msg);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function changeStatusContactWebhook(userdetails) {
  try {
    const {
      data: { msg },
    } = await axios.patch(`${BASE_URL}/contact/webhook/status`, userdetails);
    if (msg === "success") return Promise.resolve(msg);
    else return Promise.reject(msg);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function changeStatusAppointmentWebhook(userdetails) {
  try {
    const {
      data: { msg },
    } = await axios.patch(`${BASE_URL}/appointments/webhook/status`, userdetails);
    if (msg === "success") return Promise.resolve(msg);
    else return Promise.reject(msg);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function changeStatusClassBookingWebhook(userdetails) {
  try {
    const {
      data: { msg },
    } = await axios.patch(`${BASE_URL}/appointment/webhook/status`, userdetails);
    if (msg === "success") return Promise.resolve(msg);
    else return Promise.reject(msg);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function generateAppUserWebhook(type) {
  console.log(type);
  try {
    const {
      data: { msg },
    } = await axios.post(
      `${BASE_URL}/appuser/webhook/reconfigure`,
      { type },
      {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      }
    );
    if (msg === "success") return Promise.resolve(msg);
    else return Promise.reject(msg);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getActiveCreateUserWebhook() {
  try {
    const { data } = await axios.get(`${BASE_URL}/appuser/webhook/active`, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    // console.log(data)
    if (data.msg === "success") return Promise.resolve(data);
    else return Promise.reject(data.msg);
  } catch (err) {
    return Promise.reject({ error: "Auth Failed" });
  }
}
