import { Alert, Button, Divider, InputNumber, Modal, Select, Switch, Typography } from "antd";
import React, { Fragment, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { getCustomFieldDetails } from "../../../../../../helpers/GhlApi";
const { Text } = Typography;

const ServicesClientSale = ({ open, setOpen, connectionData, setConnectionData, selectedGhlAccount }) => {
  const [customFields, setCustomFields] = useState([]);
  const [localUpdatedClientSaleData, setLocalUpdatedClientSaleData] = useState(connectionData?.clientSaleEvent?.filter((element) => element.ghl_id === selectedGhlAccount)[0] ? connectionData?.clientSaleEvent.filter((element) => element.ghl_id === selectedGhlAccount)[0] : null);

  const handleCancel = () => {
    setOpen(false);
  };

  useEffect(() => {
    const reloadClientSaleDetails = () => {
      const toastBox = toast;
      const getCustomFieldPromise = getCustomFieldDetails({ ghl_id: selectedGhlAccount });
      getCustomFieldPromise
        .then(
          (data) => {
            if (data) {
              setCustomFields(data);
            }
          },
          (msg) => {
            toast.error(`${msg}`, {
              id: toastBox,
            });
          }
        )
        .catch((err) => {
          toast.error(`${err}`, {
            id: toastBox,
          });
        });
    };
    reloadClientSaleDetails();
  }, [connectionData, selectedGhlAccount]);

  const handleSaveButton = () => {
    let letSave = true;
    if (letSave) {
      //  setConnectionData((prevData) => ({ ...prevData, classCalendar: localUpdatedClientSaleData }))
      setConnectionData((prevData) => {
        let foundMatchingItem = false;

        let updatedClientSaleData = prevData.clientSaleEvent.map((item) => {
          if (item.ghl_id === selectedGhlAccount) {
            foundMatchingItem = true;
            return {
              ...item,
              ...localUpdatedClientSaleData,
            };
          }

          return item;
        });

        if (!foundMatchingItem) {
          setLocalUpdatedClientSaleData({
            ghl_id: selectedGhlAccount,
            isActive: "true",
            ...localUpdatedClientSaleData,
          });
          updatedClientSaleData.push({
            ghl_id: selectedGhlAccount,
            isActive: "true",
            ...localUpdatedClientSaleData,
          });
        }
        return {
          ...prevData,
          clientSaleEvent: updatedClientSaleData,
        };
      });
    } else toast.error(`Check your selection!`);
  };

  const handleAmountFieldSelect = (customFieldID) => {
    setLocalUpdatedClientSaleData({ ...localUpdatedClientSaleData, purchaseAmountFieldID: customFieldID });
  };

  const handleNameFieldSelect = (customFieldID) => {
    setLocalUpdatedClientSaleData({ ...localUpdatedClientSaleData, itemNameFieldID: customFieldID });
  };

  const handleTypeFieldSelect = (customFieldID) => {
    setLocalUpdatedClientSaleData({ ...localUpdatedClientSaleData, itemTypeFieldID: customFieldID });
  };

  const onDelayInputChange = (value) => {
    setLocalUpdatedClientSaleData({ ...localUpdatedClientSaleData, delay: value });
  };

  const handleActiveSelect = () => {
    setLocalUpdatedClientSaleData((prevData) => {
      return { ...localUpdatedClientSaleData, isActive: prevData?.isActive === "true" ? "false" : "true" };
    });
  };

  const handleCreateInvoiceChange = () => {
    setLocalUpdatedClientSaleData((prevData) => {
      return { ...localUpdatedClientSaleData, invoiceActive: prevData?.invoiceActive === "true" ? "false" : "true" };
    });
  };

  return (
    <Modal
      title={"Manage client sale event"}
      className="max-w-3xl"
      width={"70vw"}
      centered
      open={open}
      onOk={() => setOpen(false)}
      onCancel={() => setOpen(false)}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Return
        </Button>,
        <Fragment key="save">
          {localUpdatedClientSaleData &&
          (localUpdatedClientSaleData?.purchaseAmountFieldID || localUpdatedClientSaleData?.itemNameFieldID || localUpdatedClientSaleData?.itemTypeFieldID) &&
          JSON.stringify(localUpdatedClientSaleData) !== JSON.stringify(connectionData?.clientSaleEvent.filter((element) => element.ghl_id === selectedGhlAccount)[0]) ? (
            <Button key="save" type="default" className="bg-red-300" onClick={handleSaveButton}>
              Save & continue
            </Button>
          ) : (
            <Button key="save" type="default" className="bg-red-300" disabled>
              Save & continue
            </Button>
          )}
        </Fragment>,
      ]}
    >
      <Divider className="my-0.5" />

      <div className="flex justify-between items-center my-auto">
        <div className="pt-2 my-auto">
          <Typography.Title level={5} type="secondary">
            This event will update GHL custom fields
          </Typography.Title>
        </div>

        <Button key="save" type="dashed" className={`${localUpdatedClientSaleData?.isActive === "true" ? `bg-green-300` : `bg-gray-100`} `} onClick={() => handleActiveSelect()}>
          {localUpdatedClientSaleData?.isActive === "true" ? `Active` : `Inactive`}
        </Button>
      </div>

      <Divider className="my-0.5" />
      <div className="grid grid-cols-1 lg:grid-cols-2 mb-6 gap-4">
        <div className="flex justify-start mr-auto grid-cols-2 w-full">
          <Text className="text-base min-w-fit w-full">Item amount : </Text>
          <Select
            placeholder="Select a custom field"
            className="text-start justify-start w-full"
            defaultValue={connectionData?.clientSaleEvent?.length !== 0 ? connectionData?.clientSaleEvent.filter((element) => element.ghl_id === selectedGhlAccount)[0]?.purchaseAmountFieldID : null}
            onChange={(e) => handleAmountFieldSelect(e)}
          >
            {customFields.length !== 0 &&
              customFields.map((element) => {
                return (
                  <Select.Option key={element.id} value={element.id}>
                    {element.name}
                  </Select.Option>
                );
              })}
          </Select>
        </div>
        <div className="flex mr-auto justify-start grid-cols-2 w-full">
          <Text className="text-base w-full">Item name : </Text>
          <Select
            placeholder="Select a custom field"
            className="w-full text-start justify-start"
            defaultValue={connectionData?.clientSaleEvent?.length !== 0 ? connectionData?.clientSaleEvent.filter((element) => element.ghl_id === selectedGhlAccount)[0]?.itemNameFieldID : null}
            onChange={(e) => handleNameFieldSelect(e)}
          >
            {customFields.length !== 0 &&
              customFields.map((element) => {
                return (
                  <Select.Option key={element.id} value={element.id}>
                    {element.name}
                  </Select.Option>
                );
              })}
          </Select>
        </div>
        <div className="flex mr-auto justify-start grid-cols-2 w-full">
          <Text className="text-base min-w-fit w-full">Item type : </Text>
          <Select
            placeholder="Select a custom field"
            className="w-full text-start justify-start"
            defaultValue={connectionData?.clientSaleEvent?.length !== 0 ? connectionData?.clientSaleEvent.filter((element) => element.ghl_id === selectedGhlAccount)[0]?.itemTypeFieldID : null}
            onChange={(e) => handleTypeFieldSelect(e)}
          >
            {customFields.length !== 0 &&
              customFields.map((element) => {
                return (
                  <Select.Option key={element.id} value={element.id}>
                    {element.name}
                  </Select.Option>
                );
              })}
          </Select>
        </div>
        <div className="flex mr-auto justify-start w-full grid-cols-2">
          <Text className="text-base min-w-fit w-full">
            Delay
            <Text> (multiple items) :</Text>
          </Text>
          <InputNumber
            className="w-full text-start justify-start"
            defaultValue={connectionData?.clientSaleEvent?.length !== 0 ? connectionData?.clientSaleEvent.filter((element) => element.ghl_id === selectedGhlAccount)[0]?.delay : 10}
            min={0}
            max={120}
            formatter={(value) => `${value} sec`}
            parser={(value) => value.replace(" sec", "")}
            onChange={onDelayInputChange}
          />
        </div>
        <div className="flex mr-auto justify-start items-center grid-cols-2 w-full">
          <Text className="text-base w-full">Create invoice :</Text>
          <Switch className="bg-gray-500" defaultValue={connectionData?.clientSaleEvent?.length !== 0 ? connectionData?.clientSaleEvent.filter((element) => element.ghl_id === selectedGhlAccount)[0]?.invoiceActive === "true" : false} onChange={handleCreateInvoiceChange} />
        </div>
      </div>
    </Modal>
  );
};

export default ServicesClientSale;
