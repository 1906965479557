import { Alert, Button, Divider, Modal, Select, Typography } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import React, { Fragment, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { getMBClassAvailableCalendar, getMBAvailableClass } from "../../../../../../helpers/MbApi";
import { getAllCalendarDetails } from "../../../../../../helpers/GhlApi";
const { Text } = Typography;

const ServicesClassCalendar = ({ open, setOpen, connectionData, setConnectionData, selectedGhlAccount }) => {
  const [calendarAvailDetails, setCalendarAvailDetails] = useState([]);
  const [classAvailDetails, setClassAvailDetails] = useState([]);
  const [localUpdatedClassCalendar, setLocalUpdatedClassCalendar] = useState(
    connectionData?.classCalendar.filter((element) => element.ghl_id === selectedGhlAccount)[0]?.children
      ? connectionData?.classCalendar.filter((element) => element.ghl_id === selectedGhlAccount)[0]?.children?.length !== 0
        ? connectionData?.classCalendar.filter((element) => element.ghl_id === selectedGhlAccount)[0]?.children
        : []
      : []
  );

  const handleCancel = () => {
    setOpen(false);
  };

  useEffect(() => {
    const getMindbodyCalendarAvail = () => {
      const toastBox = toast;
      const getLocationPromise = getAllCalendarDetails({
        ghl_id: selectedGhlAccount,
      });
      getLocationPromise
        .then(
          (data) => {
            if (data) {
              setCalendarAvailDetails(data);
            }
          },
          (msg) => {
            toast.error(`${msg}`, {
              id: toastBox,
            });
          }
        )
        .catch((err) => {
          toast.error(`${err}`, {
            id: toastBox,
          });
        });
    };

    const getMindbodyClassAvail = () => {
      const toastBox = toast;
      const getMBAvailableClassPromise = getMBAvailableClass({
        mb_apikey: connectionData?.mb_apikey,
        mb_siteid: connectionData?.mb_siteid,
      });
      getMBAvailableClassPromise
        .then(
          (data) => {
            if (data) {
              setClassAvailDetails(data);
            }
          },
          (msg) => {
            toast.error(`${msg}`, {
              id: toastBox,
            });
          }
        )
        .catch((err) => {
          toast.error(`${err}`, {
            id: toastBox,
          });
        });
    };
    getMindbodyCalendarAvail();
    getMindbodyClassAvail();
  }, [connectionData, selectedGhlAccount]);

  const handleConnectionRemove = (index) => {
    setLocalUpdatedClassCalendar((prevObjects) => {
      const updatedObjects = prevObjects.filter((obj) => obj.index !== index);
      return updatedObjects;
    });
  };

  const handleCalendarSelect = (calendarId, index) => {
    setLocalUpdatedClassCalendar((prevObjects) => {
      const updatedObjects = [...prevObjects];

      const calendarName = calendarAvailDetails?.find((element) => element.id === calendarId).name;
      const objectIndex = updatedObjects.findIndex((obj) => obj.index === index);

      if (objectIndex !== -1) {
        const updatedObject = {
          ...updatedObjects[objectIndex],
          calendarId: calendarId,
          calendarName: calendarName,
        };

        updatedObjects[objectIndex] = updatedObject;
      }

      return updatedObjects;
    });
  };

  const handleClassSelect = (className, index) => {
    setLocalUpdatedClassCalendar((prevObjects) => {
      const updatedObjects = [...prevObjects];

      const objectIndex = updatedObjects.findIndex((obj) => obj.index === index);

      if (objectIndex !== -1) {
        const updatedObject = {
          ...updatedObjects[objectIndex],
          className: className,
        };

        updatedObjects[objectIndex] = updatedObject;
      }

      return updatedObjects;
    });
  };

  const handleClassBookingSelect = (index) => {
    const bookingCurrentStatus = localUpdatedClassCalendar.filter((element) => element.index === index)[0]?.bookingActive;

    setLocalUpdatedClassCalendar((prevObjects) => {
      const updatedObjects = [...prevObjects];

      const objectIndex = updatedObjects.findIndex((obj) => obj.index === index);

      if (objectIndex !== -1) {
        const updatedObject = {
          ...updatedObjects[objectIndex],
          bookingActive: bookingCurrentStatus === "true" ? "false" : "true",
        };

        updatedObjects[objectIndex] = updatedObject;
      }

      return updatedObjects;
    });
  };

  const handleClassCancellingSelect = (index) => {
    const cancelCurrentStatus = localUpdatedClassCalendar.filter((element) => element.index === index)[0]?.cancelActive;

    setLocalUpdatedClassCalendar((prevObjects) => {
      const updatedObjects = [...prevObjects];

      const objectIndex = updatedObjects.findIndex((obj) => obj.index === index);

      if (objectIndex !== -1) {
        const updatedObject = {
          ...updatedObjects[objectIndex],
          cancelActive: cancelCurrentStatus === "true" ? "false" : "true",
        };

        updatedObjects[objectIndex] = updatedObject;
      }

      return updatedObjects;
    });
  };

  const handleSaveButton = () => {
    let letSave = true;
    for (let i = 0; i < localUpdatedClassCalendar.length; i++) {
      if (localUpdatedClassCalendar[i].calendarId === "") {
        letSave = false;
        break;
      } else if (localUpdatedClassCalendar[i].className === "") {
        letSave = false;
        break;
      } else if (!letSave) {
        break;
      }
      for (let j = i + 1; j < localUpdatedClassCalendar.length; j++) {
        if (localUpdatedClassCalendar[i].calendarId === localUpdatedClassCalendar[j].calendarId || localUpdatedClassCalendar[i].className === localUpdatedClassCalendar[j].className) {
          letSave = false;
          break;
        }
      }
    }
    if (letSave) {
      //  setConnectionData((prevData) => ({ ...prevData, classCalendar: localUpdatedClassCalendar }))
      setConnectionData((prevData) => {
        let foundMatchingItem = false;

        let updatedClassCalendar = prevData.classCalendar.map((item) => {
          if (item.ghl_id === selectedGhlAccount) {
            foundMatchingItem = true;
            return {
              ...item,
              children: localUpdatedClassCalendar,
            };
          }

          return item;
        });

        if (!foundMatchingItem) {
          updatedClassCalendar.push({
            ghl_id: selectedGhlAccount,
            children: localUpdatedClassCalendar,
          });
        }
        return {
          ...prevData,
          classCalendar: updatedClassCalendar,
        };
      });
    } else toast.error(`Check your selection!`);
  };

  useEffect(() => {
    const classNamesNotInCalendar = classAvailDetails.filter((className) => {
      // Use the `some` method to check if the className exists in any of the objects in classCalendar
      return !connectionData.classCalendar.some((calendarItem) => calendarItem.children.some((child) => child.className === className));
    });
    connectionData.classCalendar.every((calendarItem) => {
      return true;
    });
  }, [classAvailDetails, connectionData]);

  return (
    <Modal
      title={"Manage connection"}
      className="max-w-3xl"
      width={"70vw"}
      centered
      open={open}
      onOk={() => setOpen(false)}
      onCancel={() => setOpen(false)}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Return
        </Button>,
        <Fragment key="save">
          {localUpdatedClassCalendar.length !== 0 && JSON.stringify(localUpdatedClassCalendar) !== JSON.stringify(connectionData?.classCalendar.filter((element) => element.ghl_id === selectedGhlAccount)[0]?.children) ? (
            <Button key="save" type="default" className="bg-red-300" onClick={handleSaveButton}>
              Save & continue
            </Button>
          ) : (
            <Button key="save" type="default" className="bg-red-300" disabled>
              Save & continue
            </Button>
          )}
        </Fragment>,
      ]}
    >
      <Divider className="my-2" />
      <Alert message="Note: Both class booking and cancellation share this connection" type="warning" />
      {localUpdatedClassCalendar.length !== 0 &&
        localUpdatedClassCalendar.map(({ index, calendarId, name, className }) => {
          return (
            <div key={index} className="grid grid-flow-col mb-6">
              <div className="grid lg:grid-cols-2 grid-cols-1 mt-2 gap-2">
                <div className="min-w-[20rem] mx-auto text-start lg:text-center">
                  <Text className="text-base">Select Calendar : </Text>
                  <Select
                    placeholder="Select a calendar"
                    className="max-w-[12rem] w-full"
                    defaultValue={connectionData?.classCalendar?.length !== 0 ? connectionData?.classCalendar.filter((element) => element.ghl_id === selectedGhlAccount)[0]?.children.filter((element) => element.index === index)[0]?.calendarId : name && calendarId}
                    onChange={(e) => handleCalendarSelect(e, index)}
                  >
                    {calendarAvailDetails.length !== 0 &&
                      calendarAvailDetails.map((element) => {
                        return (
                          <Select.Option key={element.id} value={element.id}>
                            {element.name}
                          </Select.Option>
                        );
                      })}
                  </Select>
                </div>
                <div className="min-w-[20rem] mx-auto text-start lg:text-center">
                  <Text className="text-base">Select Class : </Text>
                  <Select
                    placeholder="Select a class"
                    className="max-w-[12rem] w-full"
                    defaultValue={connectionData?.classCalendar?.length !== 0 ? connectionData?.classCalendar.filter((element) => element.ghl_id === selectedGhlAccount)[0]?.children.filter((element) => element.index === index)[0]?.className : name ? className : null}
                    onChange={(e) => handleClassSelect(e, index)}
                  >
                    {classAvailDetails.length !== 0 &&
                      classAvailDetails
                        .filter((element) => {
                          return connectionData.classCalendar.every((calendarItem) => {
                            if (calendarItem.ghl_id === selectedGhlAccount) return true;
                            else
                              return calendarItem.children.every((child) => {
                                if (child.className === element.name) return false;
                                else return true;
                              });
                          });
                        })
                        .map((element) => {
                          return (
                            <Select.Option key={element.name} value={element.name}>
                              {element.name}
                            </Select.Option>
                          );
                        })}
                  </Select>
                </div>
                <div className="min-w-[20rem] mx-auto text-start lg:text-center">
                  <Button type="dashed" className={localUpdatedClassCalendar.filter((element) => element.index === index)[0]?.bookingActive === "true" ? "w-11/12 bg-green-300" : "w-11/12"} onClick={() => handleClassBookingSelect(index)}>
                    Booking
                  </Button>
                </div>
                <div className="min-w-[20rem] mx-auto text-start lg:text-center">
                  <Button type="dashed" className={localUpdatedClassCalendar.filter((element) => element.index === index)[0]?.cancelActive === "true" ? "w-11/12 bg-green-300" : "w-11/12"} onClick={() => handleClassCancellingSelect(index)}>
                    Cancellation
                  </Button>
                </div>
              </div>
              <div className="w-full place-items-center m-auto justify-center text-center">
                <MinusCircleOutlined
                  onClick={() => {
                    handleConnectionRemove(index);
                  }}
                />
              </div>
            </div>
          );
        })}

      <div className="w-11/12 mx-auto mt-4">
        <Button
          type="dashed"
          onClick={() => {
            const length = Number(localUpdatedClassCalendar.length + 1);
            const newValue = {
              index: length + Math.random(),
              ghl_id: selectedGhlAccount,
              calendarId: "",
              className: "",
              type: "new",
              bookingActive: "false",
              cancelActive: "false",
              classBookingTags: "",
              classCancellationTags: "",
            };
            setLocalUpdatedClassCalendar((prevObjects) => [...prevObjects, newValue]);
          }}
          block
          icon={<PlusOutlined />}
        >
          Add connection
        </Button>
      </div>
    </Modal>
  );
};

export default ServicesClassCalendar;
