import { Alert, Button, Divider, Modal, Select, Space, Typography } from "antd";
import React, { Fragment, useEffect, useState } from "react";
import { getAllGHLAccountsSpecificUser, getCustomFieldDetails, updateCustomFieldDetails } from "../../../../../../helpers/GhlApi";
import { toast } from "react-hot-toast";

const { Text } = Typography;

const UpdateGHLCustomFields = ({ open, setOpen, data, manageType, setIsClientFieldPresent }) => {
  const [ghlAccountDetails, setGhlAccountDetails] = useState([]);
  const [selectedGhlAccount, setSelectedGhlAccount] = useState(manageType === "exist" ? data?.ghl_id : null);
  const [customFields, setCustomFields] = useState([]);
  const [selectedClientField, setSelectedClientField] = useState(null);
  const [selectedMembershipField, setSelectedMembershipField] = useState(null);
  const [selectedSignedInField, setSelectedSignedInField] = useState(null);
  const [selectedNotSignedInField, setSelectedNotSignedInField] = useState(null);

  const handleCancel = () => {
    setOpen(false);
  };

  const getGHLAccountsFunc = () => {
    let toastBox = toast;
    const getAllGHLAccountsPromise = getAllGHLAccountsSpecificUser();
    getAllGHLAccountsPromise
      .then(
        (data) => {
          if (data) {
            setGhlAccountDetails(data);
          }
        },
        (msg) => {
          toast.error(`${msg}`, {
            id: toastBox,
          });
        }
      )
      .catch((err) => {
        toast.error(`${err}`, {
          id: toastBox,
        });
      });
  };

  const handleAccountSelect = (e) => {
    setSelectedGhlAccount(e);
  };

  const handleMembershipFieldChange = (e) => {
    setSelectedMembershipField(e);
  };

  const handleClientFieldChange = (e) => {
    setSelectedClientField(e);
  };

  const handleNotSignedInFieldChange = (e) => {
    setSelectedNotSignedInField(e);
  };

  const handleSignedInFieldChange = (e) => {
    setSelectedSignedInField(e);
  };

  const getCustomFieldFunc = (id) => {
    let toastBox = toast;
    const getCustomFieldPromise = getCustomFieldDetails({ ghl_id: id });
    getCustomFieldPromise
      .then(
        (data) => {
          if (data) {
            setCustomFields(data);
          }
        },
        (msg) => {
          toast.error(`${msg}`, {
            id: toastBox,
          });
        }
      )
      .catch((err) => {
        toast.error(`${err}`, {
          id: toastBox,
        });
      });
  };

  useEffect(() => {
    getGHLAccountsFunc();
  }, []);

  useEffect(() => {
    setSelectedMembershipField(null);
    setSelectedClientField(null);
    setSelectedNotSignedInField(null);
    setSelectedSignedInField(null);
    if (selectedGhlAccount) getCustomFieldFunc(selectedGhlAccount);
  }, [selectedGhlAccount]);

  const handleSaveButton = () => {
    let toastBox = toast;
    let execPromise = true;
    if (selectedClientField && selectedMembershipField && selectedClientField === selectedMembershipField) execPromise = false;
    // if (ghlAccountDetails.filter((element) => element.id === selectedGhlAccount)[0]?.clientFieldID === ghlAccountDetails.filter((element) => element.id === selectedGhlAccount)[0]?.memberFieldID) execPromise = false;
    if (selectedClientField === ghlAccountDetails.filter((element) => element.id === selectedGhlAccount)[0]?.clientFieldID) execPromise = false;
    // if (selectedMembershipField === ghlAccountDetails.filter((element) => element.id === selectedGhlAccount)[0]?.memberFieldID) execPromise = false;
    // if (selectedClientField === null && selectedMembershipField !== null) {
    //   if (selectedMembershipField !== ghlAccountDetails.filter((element) => element.id === selectedGhlAccount)[0]?.clientFieldID) execPromise = true;
    // } else if (selectedClientField !== null && selectedMembershipField === null) {
    //   if (selectedClientField !== ghlAccountDetails.filter((element) => element.id === selectedGhlAccount)[0]?.memberFieldID) execPromise = true;
    // } else if (selectedClientField !== selectedMembershipField) {
    //   execPromise = true;
    // }
    if (!execPromise) {
      toast.error(`Same field cannot be selected`, {
        id: toastBox,
      });
    } else {
      toast.loading(`updating...`, {
        id: toastBox,
      });
      const updateCustomFieldPromise = updateCustomFieldDetails({ id: selectedGhlAccount, memberFieldID: selectedMembershipField, clientFieldID: selectedClientField, signedInFieldID: selectedSignedInField, notSignedInFieldID: selectedNotSignedInField });
      updateCustomFieldPromise
        .then(
          (msg) => {
            if (selectedClientField) setIsClientFieldPresent(true);
            toast.success(`Updated successfully!`, {
              id: toastBox,
            });
          },
          (msg) => {
            toast.error(`${msg}`, {
              id: toastBox,
            });
          }
        )
        .catch((err) => {
          toast.error(`${err}`, {
            id: toastBox,
          });
        });
    }
  };

  return (
    <Modal
      title={"Edit custom fields"}
      className="max-w-xl"
      width={"70vw"}
      centered
      open={open}
      onOk={() => setOpen(false)}
      onCancel={() => setOpen(false)}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Return
        </Button>,
        <Fragment key="save">
          {selectedClientField === null && selectedMembershipField === null && selectedSignedInField === null && selectedNotSignedInField === null ? (
            <Button key="save" type="default" className="bg-red-300" disabled>
              Save & continue
            </Button>
          ) : (
            <Button key="save" type="default" className="bg-red-300" onClick={handleSaveButton}>
              Save & continue
            </Button>
          )}
        </Fragment>,
      ]}
    >
      <Divider className="my-2" />
      <Space direction="vertical" className="w-full mx-auto">
        <Alert message="Warning!!! Any changes made will affect all active connections." type="warning" />
        <div className="flex place-items-center flex-wrap">
          <Text className="text-base">GoHighLevel account : </Text>
          <Select style={{ width: "15vw" }} placeholder="Select GoHighLevel account" className="ml-4" value={selectedGhlAccount} onChange={(e) => handleAccountSelect(e)}>
            {ghlAccountDetails.length !== 0 &&
              ghlAccountDetails.map((data, index) => {
                return (
                  <Select.Option key={index} value={data.id}>
                    {data.name}
                  </Select.Option>
                );
              })}
          </Select>
        </div>
        <Divider className="my-0" />
        <div className="flex place-items-center flex-wrap">
          <Text className="text-base">Client Id : </Text>
          <Select
            style={{
              width: 160,
            }}
            className="ml-4"
            placeholder="Select custom field"
            //defaultValue={manageType === "exist" && ghlAccountDetails.length !== 0 && ghlAccountDetails.filter((element) => element.id === selectedGhlAccount)[0]?.clientFieldID}
            value={selectedClientField !== null ? selectedClientField : manageType === "exist" && ghlAccountDetails.length !== 0 && ghlAccountDetails.filter((element) => element.id === selectedGhlAccount)[0]?.clientFieldID}
            onChange={handleClientFieldChange}
          >
            {customFields.length !== 0 &&
              customFields.map((data, index) => {
                return (
                  <Select.Option key={index} value={data.id}>
                    {data.name}
                  </Select.Option>
                );
              })}
          </Select>
        </div>
        <div className="flex place-items-center flex-wrap">
          <Text className="text-base">Contract Name : </Text>
          <Select
            style={{
              width: 160,
            }}
            className="ml-4"
            placeholder="Select custom field"
            // defaultValue={manageType === "exist" && ghlAccountDetails.length !== 0 && ghlAccountDetails.filter((element) => element.id === selectedGhlAccount)[0]?.memberFieldID}
            value={selectedMembershipField !== null ? selectedMembershipField : manageType === "exist" && ghlAccountDetails.length !== 0 && ghlAccountDetails.filter((element) => element.id === selectedGhlAccount)[0]?.memberFieldID}
            onChange={handleMembershipFieldChange}
          >
            {customFields.length !== 0 &&
              customFields.map((data, index) => {
                return (
                  <Select.Option key={index} value={data.id}>
                    {data.name}
                  </Select.Option>
                );
              })}
          </Select>
        </div>
        <div className="flex place-items-center flex-wrap">
          <Text className="text-base">Class Signed In : </Text>
          <Select
            style={{
              width: 160,
            }}
            className="ml-4"
            placeholder="Select custom field"
            //defaultValue={manageType === "exist" && ghlAccountDetails.length !== 0 && ghlAccountDetails.filter((element) => element.id === selectedGhlAccount)[0]?.clientFieldID}
            value={selectedSignedInField !== null ? selectedSignedInField : manageType === "exist" && ghlAccountDetails.length !== 0 && ghlAccountDetails.filter((element) => element.id === selectedGhlAccount)[0]?.signedInFieldID}
            onChange={handleSignedInFieldChange}
          >
            {customFields.length !== 0 &&
              customFields.map((data, index) => {
                return (
                  <Select.Option key={index} value={data.id}>
                    {data.name}
                  </Select.Option>
                );
              })}
          </Select>
        </div>
        <div className="flex place-items-center flex-wrap">
          <Text className="text-base">Class Not Signed In : </Text>
          <Select
            style={{
              width: 160,
            }}
            className="ml-4"
            placeholder="Select custom field"
            //defaultValue={manageType === "exist" && ghlAccountDetails.length !== 0 && ghlAccountDetails.filter((element) => element.id === selectedGhlAccount)[0]?.clientFieldID}
            value={selectedNotSignedInField !== null ? selectedNotSignedInField : manageType === "exist" && ghlAccountDetails.length !== 0 && ghlAccountDetails.filter((element) => element.id === selectedGhlAccount)[0]?.notSignedInFieldID}
            onChange={handleNotSignedInFieldChange}
          >
            {customFields.length !== 0 &&
              customFields.map((data, index) => {
                return (
                  <Select.Option key={index} value={data.id}>
                    {data.name}
                  </Select.Option>
                );
              })}
          </Select>
        </div>
      </Space>
    </Modal>
  );
};

export default UpdateGHLCustomFields;
