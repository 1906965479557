import { Alert, Button, Input, InputNumber, Select, Spin, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { getContractDetailsForGhl, getLocationDetailsForGhl, getServiceDetailsForGhl } from "../../../../../../helpers/AppUserApi";
const { Text } = Typography;

const LinkMembershipGhlConnection = ({ connectionData, setConnectionData, manageType }) => {
  const [mbLocation, setMbLocation] = useState([]);
  const [mbContracts, setMbContracts] = useState([]);
  const [mbServices, setMbServices] = useState([]);
  const [dataLoader, setDataLoader] = useState({
    mbLocations: false,
    mbContracts: false,
    mbServices: false,
  });

  useEffect(() => {
    const getMindbodyLocationAvail = () => {
      const toastBox = toast;
      const getMBAvailableLocationPromise = getLocationDetailsForGhl({
        apikey: connectionData?.mb_apikey,
        siteid: connectionData?.mb_siteid,
      });
      getMBAvailableLocationPromise
        .then(
          (data) => {
            if (data) {
              setMbLocation(data);
            }
          },
          (msg) => {
            toast.error(`${msg}`, {
              id: toastBox,
            });
          }
        )
        .catch((err) => {
          toast.error(`${err}`, {
            id: toastBox,
          });
        })
        .finally(() => {
          setDataLoader((prevData) => ({ ...prevData, mbLocations: true }));
        });
    };
    getMindbodyLocationAvail();
  }, [connectionData]);

  useEffect(() => {
    const getMindbodyContractAvail = () => {
      const toastBox = toast;
      const getMBAvailableLocationPromise = getContractDetailsForGhl({
        mb_apikey: connectionData?.mb_apikey,
        mb_siteid: connectionData?.mb_siteid,
        locationId: connectionData?.locationId,
      });
      getMBAvailableLocationPromise
        .then(
          (data) => {
            if (data) {
              setMbContracts(data);
            }
          },
          (msg) => {
            toast.error(`${msg}`, {
              id: toastBox,
            });
          }
        )
        .catch((err) => {
          toast.error(`${err}`, {
            id: toastBox,
          });
        })
        .finally(() => {
          setDataLoader((prevData) => ({ ...prevData, mbContracts: true }));
        });
    };

    const getMindbodyServiceAvail = () => {
      const toastBox = toast;
      const getMBAvailableLocationPromise = getServiceDetailsForGhl({
        mb_apikey: connectionData?.mb_apikey,
        mb_siteid: connectionData?.mb_siteid,
      });
      getMBAvailableLocationPromise
        .then(
          (data) => {
            if (data) {
              setMbServices(data);
            }
          },
          (msg) => {
            toast.error(`${msg}`, {
              id: toastBox,
            });
          }
        )
        .catch((err) => {
          toast.error(`${err}`, {
            id: toastBox,
          });
        })
        .finally(() => {
          setDataLoader((prevData) => ({ ...prevData, mbServices: true }));
        });
    };

    if (connectionData?.locationId && (connectionData?.locationId !== null || connectionData?.locationId !== "")) {
      getMindbodyContractAvail();
      getMindbodyServiceAvail();
    }
  }, [connectionData?.locationId]);

  // const handleConnectionRemove = (index) => {
  //   setConnectionData((prevConnectionData) => {
  //     const updatedClassCalendar = [...prevConnectionData.classCalendar];
  //     const updatedObjects = updatedClassCalendar.filter((obj) => obj.index !== index);
  //     return {
  //       ...prevConnectionData,
  //       classCalendar: updatedObjects,
  //     };
  //   });
  // };

  const handleLocationSelect = (locationId) => {
    setConnectionData({ ...connectionData, locationId: locationId });
  };

  const handleContractSelect = (contractId) => {
    const contractItems = mbContracts.filter((element) => element.Id.toString() === contractId)[0]?.ContractItems;
    const transformedItems = contractItems?.map((element) => ({
      itemType: "Item",
      itemId: element.Id,
      itemName: element.Name,
    }));
    setConnectionData({ ...connectionData, contractId: contractId, contractName: mbContracts.filter((element) => element.Id.toString() === contractId)[0]?.Name, membershipItems: transformedItems });
  };

  // const handleServiceSelect = (serviceIds) => {
  //   const contractItems = mbServices.filter((element) => serviceIds.filter((serviceItem) => element.Id.toString() === serviceItem.toString())?.length !== 0);
  //   const transformedItems = contractItems?.map((element) => ({
  //     itemType: "Item",
  //     itemId: element.Id,
  //     itemName: element.Name,
  //   }));
  //   setConnectionData({ ...connectionData, serviceId: serviceIds, serviceName: transformedItems, membershipItems: transformedItems });
  // };

  const handleServiceSelect = (serviceIds) => {
    const contractItems = mbServices.filter((element) => element.Id.toString() === serviceIds.toString());
    const transformedItems = contractItems?.map((element) => ({
      itemType: "Item",
      itemId: element.Id,
      itemName: element.Name,
    }));
    setConnectionData({ ...connectionData, serviceId: serviceIds, serviceName: contractItems[0]?.Name });
  };

  const handlePurchaseTypeSelect = (value) => {
    setConnectionData({ ...connectionData, purchaseType: value });
  };

  const handlePromocodeChange = (event) => {
    setConnectionData({ ...connectionData, promocode: event.target.value });
  };
  const handleDiscountChange = (value) => {
    setConnectionData({ ...connectionData, offerPercent: value });
  };

  return (
    <>
      {manageType === "exist" ? <Alert message="Note: Configuration cannot be edited since it's not allowed via Mindbody API" type="warning" /> : <Alert message="Note: Configuration cannot be edited later since it's not allowed via Mindbody API" type="warning" />}
      <div className="grid grid-flow-col mb-6">
        <div className="grid lg:grid-cols-1 grid-cols-1 mt-2 gap-2">
          <div className="min-w-[20rem] text-start lg:text-center flex items-center gap-4 border-b pb-2">
            <Text className="text-base">Select Location : </Text>
            <Spin spinning={!dataLoader.mbLocations} className="max-w-[12rem] w-full">
              <Select placeholder="Select a location" className="max-w-[12rem] w-full" defaultValue={connectionData?.locationId.toString() || null} onChange={(e) => handleLocationSelect(e)} disabled={manageType === "exist" ? true : false}>
                {mbLocation.length !== 0 &&
                  mbLocation.map((element) => {
                    return (
                      <Select.Option key={element.locationId} value={element.locationId.toString()}>
                        {element.name}
                      </Select.Option>
                    );
                  })}
              </Select>
            </Spin>
          </div>
          <div className="grid 3xl:grid-cols-2 grid-cols-1 gap-2 border-b pb-2">
            <div className="min-w-[20rem] text-start lg:text-center flex items-center gap-4">
              <Text className="text-base capitalize">purchase type : </Text>
              <Spin spinning={!dataLoader.mbLocations} className="w-full">
                <Select
                  placeholder="Select purchase type"
                  className="max-w-[12rem] w-full"
                  value={connectionData?.purchaseType?.toString() || null}
                  onChange={(e) => handlePurchaseTypeSelect(e)}
                  disabled={connectionData?.locationId === "" || connectionData?.locationId === null ? true : manageType === "exist" ? true : false}
                >
                  <Select.Option value="contract">Contract</Select.Option>
                  <Select.Option value="service">Custom Services</Select.Option>
                </Select>
              </Spin>
            </div>
            <div className={`min-w-[20rem] text-start lg:text-center items-center gap-4 ${connectionData?.purchaseType === "service" ? `flex` : `hidden`}`}>
              <Text className="text-base">Select Service : </Text>
              <Spin spinning={!dataLoader.mbLocations} className="w-full">
                <Select
                  // mode="multiple"
                  // allowClear
                  placeholder="Select single/multiple service"
                  className="max-w-[12rem] w-full"
                  style={{
                    minWidth: 200,
                  }}
                  value={connectionData?.serviceId.toString() || null}
                  onChange={(e) => handleServiceSelect(e)}
                  disabled={connectionData?.locationId === "" || connectionData?.locationId === null ? true : manageType === "exist" ? true : false}
                >
                  {mbServices.length !== 0 &&
                    mbServices.map((element) => {
                      return (
                        <Select.Option key={element.Id} value={element.Id.toString()}>
                          {element.Name}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Spin>
            </div>
            <div className={`min-w-[20rem] text-start lg:text-center items-center gap-4 ${connectionData?.purchaseType === "contract" ? `flex` : `hidden`}`}>
              <Text className="text-base">Select Contract : </Text>
              <Spin spinning={!dataLoader.mbLocations} className="w-full">
                <Select
                  placeholder="Select a contract"
                  className="max-w-[12rem] w-full"
                  value={connectionData?.contractId.toString() || null}
                  onChange={(e) => handleContractSelect(e)}
                  disabled={connectionData?.locationId === "" || connectionData?.locationId === null ? true : manageType === "exist" ? true : false}
                >
                  {mbContracts.length !== 0 &&
                    mbContracts.map((element) => {
                      return (
                        <Select.Option key={element.Id} value={element.Id.toString()}>
                          {element.Name}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Spin>
            </div>
          </div>

          <div className="grid 3xl:grid-cols-2 grid-cols-1 gap-2 pb-2">
            <div className="min-w-[20rem] text-start lg:text-center flex items-center gap-4">
              <Text className="text-base">Promocode : </Text>
              <Input placeholder="Enter a name" className="max-w-[12rem] w-full" value={connectionData?.promocode || null} onChange={(e) => handlePromocodeChange(e)} disabled={connectionData?.locationId === "" || connectionData?.locationId === null ? true : manageType === "exist" ? true : false} />
            </div>
            <div className="min-w-[20rem] text-start lg:text-center flex items-center gap-4">
              <Text className="text-base">Discount : </Text>
              {/* <InputNumber
                placeholder="Enter percentage amount"
                className="max-w-[12rem] w-full"
                value={connectionData?.offerPercent || 0}
                onChange={(e) => handleDiscountChange(e)}
                disabled={connectionData?.locationId === "" || connectionData?.locationId === null ? true : manageType === "exist" ? true : false}
              /> */}
              <InputNumber
                defaultValue={100}
                min={0}
                max={100}
                value={Number(connectionData?.offerPercent) || 0}
                formatter={(value) => `${value}%`}
                parser={(value) => value?.replace("%", "")}
                onChange={(e) => handleDiscountChange(e)}
                disabled={connectionData?.locationId === "" || connectionData?.locationId === null ? true : manageType === "exist" ? true : false}
              />
            </div>
          </div>
        </div>
      </div>

      {/* <div className="w-11/12 mx-auto mt-4">
        <Button
          type="dashed"
          onClick={() => {
            const length = Number(connectionData?.classCalendar.length + 1);
            const newValue = {
              index: length + Math.random(),
              calendarId: "",
              className: "",
              type: "new",
              bookingActive: "false",
              cancelActive: "false",
            };
            setConnectionData((prevConnectionData) => {
              return {
                ...prevConnectionData,
                classCalendar: [...prevConnectionData.classCalendar, newValue],
              };
            });
          }}
          block
          icon={<PlusOutlined />}
        >
          Add connection
        </Button>
      </div> */}
    </>
  );
};

export default LinkMembershipGhlConnection;
