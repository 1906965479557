import React, { useEffect, useState } from "react";
import { Alert, Button, Divider, Input, Select, Space, Spin, Switch, Typography } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { toast } from "react-hot-toast";
import AddIntegrationModal from "../globalComponents/AddIntegrationModal";
import { getMtAvailAccounts } from "../../../../../../helpers/MtApi";
const { Option } = Select;

const LinkAccountMtToGhlConnection = ({ data, setData }) => {
  const [mtAvailAccounts, setMtAvailAccounts] = useState([]);
  const [addNewAccountModal, setAddNewAccountModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [dataLoader, setDataLoader] = useState({
    mtAccounts: false,
  });

  const handleMTAccountSelect = (e) => {
    setData({ ...data, mt_id: e });
  };

  const getMBContactAvailAccFunc = () => {
    let toastBox = toast;
    const getAvailAccountPromise = getMtAvailAccounts();
    getAvailAccountPromise
      .then(
        (data) => {
          if (data) setMtAvailAccounts(data);
        },
        (msg) => {
          toast.error(`${msg}`, {
            id: toastBox,
          });
        }
      )
      .catch((err) => {
        toast.error(`${err}`, {
          id: toastBox,
        });
      })
      .finally(() => {
        setDataLoader((prevData) => ({ ...prevData, mtAccounts: true }));
      });
  };

  const handleInputChange = (e) => {
    const { value } = e.target;
    setData((prevData) => ({
      ...prevData,
      connectionName: value,
    }));
  };

  useEffect(() => {
    getMBContactAvailAccFunc();
  }, []);

  useEffect(() => {
    if (dataLoader.mtAccounts) setLoading(false);
  }, [dataLoader]);

  const handleNewAccountMindbodyButton = () => {
    setAddNewAccountModal(!addNewAccountModal);
  };

  return (
    <Spin spinning={loading} className="my-6">
      <AddIntegrationModal setOpen={(bool) => setAddNewAccountModal(bool)} open={addNewAccountModal} type={"mindbody"} />
      <div className="w-10/12 mx-auto mt-2">
        <div className="mb-4">
          <Alert type="info" className="bg-gray-100 text-gray-700 border-gray-500" message={"Select GoHighLevel account separately in next step."} />
        </div>
        <Input addonBefore="Connection name" onChange={handleInputChange} value={data?.connectionName} placeholder="Enter Name" className="max-w-lg" />
        <div className="mt-4">
          <Typography.Text>MarianaTek account : </Typography.Text>
          <Select
            placeholder="Select an account"
            className="ml-6 lg:max-w-xs lg:w-1/2 xl:w-full"
            onChange={handleMTAccountSelect}
            defaultValue={data?.mt_id !== "" ? data?.mt_id : null}
            dropdownRender={(menu) => (
              <>
                {menu}
                <Divider
                  style={{
                    margin: "8px 0",
                  }}
                />
                <Space
                  style={{
                    padding: "0 8px 4px",
                  }}
                >
                  <Button type="text" icon={<PlusOutlined />} onClick={handleNewAccountMindbodyButton}>
                    Add new account
                  </Button>
                </Space>
              </>
            )}
          >
            {mtAvailAccounts.length !== 0 &&
              mtAvailAccounts.map((data, index) => {
                return (
                  <Option key={index} value={data?.id}>
                    {data?.name}
                  </Option>
                );
              })}
          </Select>
        </div>
      </div>
    </Spin>
  );
};
export default LinkAccountMtToGhlConnection;
