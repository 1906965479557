import axios from "axios";
import { API_URL, APP_USER_BASE_URL } from "./AppApiUrls";

const BASE_URL = `${API_URL}${APP_USER_BASE_URL}`;
//const BASE_URL = "http://localhost:8000/app/api/user";
//const BASE_URL = "http://13.232.11.86:8000/app/api/user";
//const BASE_URL = "http://192.168.0.109:8000/app/api/user";

export const axiosPrivate = axios.create({
  baseURL: `${BASE_URL}/refresh`,
  headers: { "Content-Type": "application/json" },
  withCredentials: true,
});

export async function registerUser(userdetails) {
  try {
    const { data } = await axios.post(`${BASE_URL}/register`, userdetails, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    if (data?.success) return Promise.resolve(data?.data);
    else return Promise.reject(data?.reason);
  } catch (err) {
    console.log(err?.message);
    return Promise.reject({ error: err?.message || "Unknown error occured" });
  }
}

export async function updateUser(userdetails) {
  try {
    const { data } = await axios.patch(`${BASE_URL}/update`, userdetails, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    if (data?.success) return Promise.resolve(data?.data);
    else return Promise.reject(data?.reason);
  } catch (err) {
    console.log(err?.message);
    return Promise.reject({ error: err?.message || "Unknown error occured" });
  }
}

export async function loginUser(userdetails) {
  try {
    const data = await axios.post(`${BASE_URL}/login`, userdetails, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function logoutUser() {
  try {
    const data = await axios.post(
      `${BASE_URL}/logout`,
      {},
      {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      }
    );
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function RefreshUserToken() {
  try {
    const data = await axios.post(`${BASE_URL}/refresh`, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function subAccountLoginUser(userdetails) {
  try {
    const { data } = await axios.post(`${BASE_URL}/login/via/admin`, userdetails, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    if (data?.msg === "Login Successful") return Promise.resolve(data);
    else return Promise.reject(data);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function switchBackToAdminAccount() {
  try {
    const { data } = await axios.get(`${BASE_URL}/login/back`, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    if (data?.msg === "Login Successful") return Promise.resolve(data);
    else return Promise.reject(data);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function accountStatusUpdate(userData) {
  try {
    const userToken = await localStorage.getItem("token");
    const { data } = await axios.post(`${BASE_URL}/update/manual`, userData, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    // console.log(data)
    if (data.msg === "success") return Promise.resolve();
  } catch (err) {
    return Promise.reject({ error: "Auth Failed" });
  }
}

export async function deleteUser(webdetails) {
  try {
    const userToken = await localStorage.getItem("token");
    const {
      data: { msg },
    } = await axios.delete(`${BASE_URL}/delete`, {
      data: webdetails,
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    if (msg === "success") return Promise.resolve(msg);
    else return Promise.reject(msg);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function activeUser() {
  try {
    const { data } = await axios.get(`${BASE_URL}/auth`, {
      withCredentials: true,
    });
    // console.log(data)
    if (data.msg === "active" || data.msg === "deactivated") return Promise.resolve(data);
    else return Promise.reject({ msg: "NO_USER_LOGIN" });
  } catch (err) {
    return Promise.reject({ error: "Auth Failed" });
  }
}

export async function getAllUserData() {
  try {
    const { data } = await axios.get(`${BASE_URL}/allusers`, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    // console.log(data)
    if (data.msg === "success") return Promise.resolve(data.result);
    else return Promise.reject("Failed to retrieve users");
  } catch (err) {
    return Promise.reject({ error: "Auth Failed" });
  }
}

export async function getGHLIntegrationDetails() {
  try {
    const userToken = await localStorage.getItem("token");
    const { data } = await axios.get(`${BASE_URL}/ghlintegrations`, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    // console.log(data)
    if (data.msg === "success") return Promise.resolve(data.result);
    else return Promise.reject({ msg: "NO_USER_LOGIN" });
  } catch (err) {
    return Promise.reject({ error: "Auth Failed" });
  }
}

export async function getGHLAvailAccounts() {
  try {
    const userToken = await localStorage.getItem("token");
    const { data } = await axios.get(`${BASE_URL}/ghlavailacc`, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    // console.log(data)
    if (data.msg === "success") return Promise.resolve(data.result);
    else return Promise.reject({ msg: "NO_USER_LOGIN" });
  } catch (err) {
    return Promise.reject({ error: "Auth Failed" });
  }
}

export async function getMBContactAvailAccounts() {
  try {
    const userToken = await localStorage.getItem("token");
    const { data } = await axios.get(`${BASE_URL}/mbcontactavailacc`, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    // console.log(data)
    if (data.msg === "success") return Promise.resolve(data.result);
    else return Promise.reject({ msg: "NO_USER_LOGIN" });
  } catch (err) {
    return Promise.reject({ error: "Auth Failed" });
  }
}

export async function getMindbodyAccountAvail() {
  try {
    const userToken = await localStorage.getItem("token");
    const { data } = await axios.get(`${BASE_URL}/mindbody/account/available`, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    // console.log(data)
    if (data.msg === "success") return Promise.resolve(data.result);
    else return Promise.reject({ msg: "NO_USER_LOGIN" });
  } catch (err) {
    return Promise.reject({ error: "Auth Failed" });
  }
}

export async function getMBAvailAccounts() {
  try {
    const userToken = await localStorage.getItem("token");
    const { data } = await axios.get(`${BASE_URL}/mbavailacc`, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    // console.log(data)
    if (data.msg === "success") return Promise.resolve(data.result);
    else return Promise.reject({ msg: "NO_USER_LOGIN" });
  } catch (err) {
    return Promise.reject({ error: "Auth Failed" });
  }
}

export async function getMBAppointmentAvailAccounts() {
  try {
    const userToken = await localStorage.getItem("token");
    const { data } = await axios.get(`${BASE_URL}/mbappointmentavailacc`, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    // console.log(data)
    if (data.msg === "success") return Promise.resolve(data.result);
    else return Promise.reject({ msg: "NO_USER_LOGIN" });
  } catch (err) {
    return Promise.reject({ error: "Auth Failed" });
  }
}

export async function getMBIntegrationDetails() {
  try {
    const userToken = await localStorage.getItem("token");
    const { data } = await axios.get(`${BASE_URL}/mbintegrations`, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    // console.log(data)
    if (data.msg === "success") return Promise.resolve(data.result);
    else return Promise.reject({ msg: "NO_USER_LOGIN" });
  } catch (err) {
    return Promise.reject({ error: "Auth Failed" });
  }
}

export async function getUserAuthDetails(userdetails) {
  try {
    const {
      data: { msg },
    } = await axios.post(`${BASE_URL}/firstentrycheck`, userdetails);
    if (msg === "success") return Promise.resolve(msg);
    else return Promise.reject(msg);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getCalendarDetails(apidetails) {
  try {
    const userToken = await localStorage.getItem("token");
    const {
      data: { msg, result },
    } = await axios.post(`${BASE_URL}/calendar`, apidetails, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    if (msg === "success") return Promise.resolve(result);
    else return Promise.reject(msg);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getAppointmentCalendarDetails(apidetails) {
  try {
    const userToken = await localStorage.getItem("token");
    const {
      data: { msg, result },
    } = await axios.post(`${BASE_URL}/appointment/calendar`, apidetails, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    if (msg === "success") return Promise.resolve(result);
    else return Promise.reject(msg);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getCalendarDetailsForGhl(apidetails) {
  try {
    const userToken = await localStorage.getItem("token");
    const {
      data: { msg, result },
    } = await axios.post(`${BASE_URL}/gohighlevel/calendar`, apidetails, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    if (msg === "success") return Promise.resolve(result);
    else return Promise.reject(msg);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getAppointmentCalendarDetailsForGhl(apidetails) {
  try {
    const userToken = await localStorage.getItem("token");
    const {
      data: { msg, result },
    } = await axios.post(`${BASE_URL}/gohighlevel/appointment/calendar`, apidetails, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    if (msg === "success") return Promise.resolve(result);
    else return Promise.reject(msg);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getClassDetails(apidetails) {
  try {
    const userToken = await localStorage.getItem("token");
    const {
      data: { msg, result },
    } = await axios.post(`${BASE_URL}/class`, apidetails, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    if (msg === "success") return Promise.resolve(result);
    else return Promise.reject(msg);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getClassDetailsForGhl(apidetails) {
  try {
    const userToken = await localStorage.getItem("token");
    const {
      data: { msg, result },
    } = await axios.post(`${BASE_URL}/mindbody/class`, apidetails, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    if (msg === "success") return Promise.resolve(result);
    else return Promise.reject(msg);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getLocationDetailsForGhl(apidetails) {
  try {
    const userToken = await localStorage.getItem("token");
    const {
      data: { msg, result },
    } = await axios.post(`${BASE_URL}/mindbody/location`, apidetails, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    if (msg === "success") return Promise.resolve(result);
    else return Promise.reject(msg);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getContractDetailsForGhl(apidetails) {
  try {
    const userToken = await localStorage.getItem("token");
    const {
      data: { msg, result },
    } = await axios.post(`${BASE_URL}/mindbody/contracts`, apidetails, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    if (msg === "success") return Promise.resolve(result);
    else return Promise.reject(msg);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getServiceDetailsForGhl(apidetails) {
  try {
    const userToken = await localStorage.getItem("token");
    const {
      data: { msg, result },
    } = await axios.post(`${BASE_URL}/mindbody/services`, apidetails, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    if (msg === "success") return Promise.resolve(result);
    else return Promise.reject(msg);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getSessionDetailsForGhl(apidetails) {
  try {
    const userToken = await localStorage.getItem("token");
    const {
      data: { msg, result },
    } = await axios.post(`${BASE_URL}/mindbody/session`, apidetails, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    if (msg === "success") return Promise.resolve(result);
    else return Promise.reject(msg);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getMindbodyProgramDetails(apidetails) {
  try {
    const { data } = await axios.post(`${BASE_URL}/mindbody/program`, apidetails, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    if (data?.success === true) return Promise.resolve(data);
    else return Promise.reject(data);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getMindbodyStaffDetails(apidetails) {
  try {
    const { data } = await axios.post(`${BASE_URL}/mindbody/staff`, apidetails, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    if (data?.success === true) return Promise.resolve(data);
    else return Promise.reject(data);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getClientContactsTransferred() {
  try {
    const userToken = await localStorage.getItem("token");
    const { data } = await axios.get(`${BASE_URL}/webhook/usage`, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    // console.log(data)
    if (data.msg === "success") return Promise.resolve(data.result);
    else return Promise.reject({ msg: "NO_USER_LOGIN" });
  } catch (err) {
    return Promise.reject({ error: "Auth Failed" });
  }
}

export async function getTotalWebhookUsage() {
  try {
    const userToken = await localStorage.getItem("token");
    const { data } = await axios.get(`${BASE_URL}/webhook/totalusage`, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    // console.log(data)
    if (data.msg === "success") return Promise.resolve(data.result);
    else return Promise.reject({ msg: "NO_USER_LOGIN" });
  } catch (err) {
    return Promise.reject({ error: "Auth Failed" });
  }
}

export async function checkMinimumAccounts() {
  try {
    const { data } = await axios.get(`${BASE_URL}/account/accounts`, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    if (data?.success) return Promise.resolve(data?.data);
    else return Promise.reject(data?.reason);
  } catch (err) {
    console.log(err?.message);
    return Promise.reject({ error: err?.message || "Unknown error occured" });
  }
}

export async function getUserDetails() {
  try {
    const { data } = await axios.get(`${BASE_URL}/userdetails`, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    // console.log(data)
    if (data.msg === "success") return Promise.resolve(data.result);
    else return Promise.reject({ msg: "No user found" });
  } catch (err) {
    return Promise.reject({ error: "Auth Failed" });
  }
}

export async function setUserName(details) {
  try {
    const userToken = await localStorage.getItem("token");
    const { data } = await axios.patch(`${BASE_URL}/changename`, details, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    // console.log(data)
    if (data.msg === "success") return Promise.resolve(data.msg);
    else return Promise.reject({ msg: "No user found" });
  } catch (err) {
    return Promise.reject({ error: "Auth Failed" });
  }
}

export async function setUserPassword(details) {
  try {
    const userToken = await localStorage.getItem("token");
    const { data } = await axios.patch(`${BASE_URL}/changepass`, details, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    // console.log(data)
    if (data.msg === "success") return Promise.resolve(data.msg);
    else return Promise.reject(data.msg);
  } catch (err) {
    return Promise.reject({ error: "Auth Failed" });
  }
}

export async function changeUserCountry(details) {
  try {
    const { data } = await axios.post(`${BASE_URL}/countrycode`, details, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    // console.log(data)
    if (data.msg === "success") return Promise.resolve(data.msg);
    else return Promise.reject(data.msg);
  } catch (err) {
    return Promise.reject({ error: "Auth Failed" });
  }
}

export async function showConnectionStatistics(event) {
  try {
    const { data } = await axios.get(`${BASE_URL}/connection/statistics`, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
      params: { event: event },
    });
    // console.log(data)
    if (data.msg === "success") return Promise.resolve(data.result);
    else {
      console.log(data?.reason);
      return Promise.reject({ msg: data.msg });
    }
  } catch (err) {
    return Promise.reject({ error: "Auth Failed" });
  }
}

export async function showTotalApiUsageStatistics() {
  try {
    const { data } = await axios.get(`${BASE_URL}/connection/totalapiusage`, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    // console.log(data)
    if (data.msg === "success") return Promise.resolve(data.result);
    else {
      console.log(data?.reason);
      return Promise.reject({ msg: data.msg });
    }
  } catch (err) {
    return Promise.reject({ error: "Auth Failed" });
  }
}
